import { Pipe, PipeTransform } from '@angular/core';
import { TimeHelper } from 'tffl-core';

@Pipe({
    name: 'time'
})
export class TimePipe implements PipeTransform {

    constructor(
    ) { }

    transform(value: any, args?: any): any {

        /* time in milliseconds */
        let timeMilliseconds = value;

        /* conversion type. eg. seconds, minutes */
        let conversionType = args;

        switch (conversionType) {
            case 'minutes':
                return TimeHelper.getMinutesString(timeMilliseconds);
            case 'seconds':
                return TimeHelper.getSecondsString(timeMilliseconds);
            case 'milliseconds':
                return TimeHelper.getMillisecondsString(timeMilliseconds);
            default:
                throw new Error('Could not understand time conversion to ' + conversionType);
        }
    }

}
