<div class="flex-container">
    <button mat-button
            [matMenuTriggerFor]="programsMenu">
        <span class="mr-2"><i class="fas fa-fw fa-football-ball"></i></span>
        Programs
        <span class="ml-2"><i class="fas fa-chevron-down"></i></span>
    </button>
    <button mat-button
            [matMenuTriggerFor]="scheduleMenu">
        <span class="mr-2"><i class="far fa-fw fa-calendar"></i></span>
        Schedule
        <span class="ml-2"><i class="fas fa-chevron-down"></i></span>
    </button>
    <button mat-button
            [matMenuTriggerFor]="statsMenu">
        <span class="mr-2"><i class="fas fa-fw fa-chart-line"></i></span>
        Stats/Standings
        <span class="ml-2"><i class="fas fa-chevron-down"></i></span>
    </button>
    <a mat-button
       routerLink="/info/get-involved">
        <span class="mr-2"><i class="fas fa-fw fa-handshake"></i></span>
        Get Involved
    </a>
    <button mat-button
            [matMenuTriggerFor]="infoMenu">
        <span class="mr-2"><i class="fas fa-fw fa-info"></i></span>
        Info
        <span class="ml-2"><i class="fas fa-chevron-down"></i></span>
    </button>
</div>
<mat-menu #programsMenu="matMenu">
    <ng-template matMenuContent>
        <a mat-menu-item
           routerLink="/programs/youth">
            <span class="mr-2"><i class="fas fa-fw fa-child"></i></span>
            Youth (Ages 9-16)
        </a>
        <a mat-menu-item
           routerLink="/programs/girls">
            <span class="mr-2"><i class="fas fa-fw fa-female"></i></span>
            Girls (Ages 9-16)
        </a>
        <a mat-menu-item
           routerLink="/programs/little">
            <span class="mr-2"><i class="fas fa-fw fa-graduation-cap"></i></span>
            Learn to Play (Ages 5-8)
        </a>
        <a mat-menu-item
           routerLink="/programs/adult">
            <span class="mr-2"><i class="fas fa-fw fa-user-tie"></i></span>
            Adult (Ages 17+)
        </a>
        <a mat-menu-item
           routerLink="/programs">
            <span class="mr-2"><i class="fas fa-fw fa-football-ball"></i></span>
            All Programs
        </a>
    </ng-template>
</mat-menu>
<mat-menu #scheduleMenu="matMenu">
    <ng-template matMenuContent>
        <a mat-menu-item
           routerLink="/attendance">
            <span class="mr-2"><i class="fas fa-fw fa-football-ball"></i></span>
            My Games
        </a>
        <a mat-menu-item
           routerLink="/schedule">
            <span class="mr-2"><i class="far fa-fw fa-calendar"></i></span>
            League Schedule
        </a>
    </ng-template>
</mat-menu>
<mat-menu #statsMenu="matMenu">
    <ng-template matMenuContent>
        <a mat-menu-item
           routerLink="/statistics">
            <span class="mr-2"><i class="fas fa-fw fa-chart-line"></i></span>
            Statistics
        </a>
        <a mat-menu-item
           routerLink="/standings">
            <span class="mr-2"><i class="fas fa-fw fa-trophy"></i></span>
            Standings
        </a>
    </ng-template>
</mat-menu>
<mat-menu #infoMenu="matMenu"
          xPosition="before">
    <ng-template matMenuContent>
        <a mat-menu-item
           href="//blog.torontoflagfootball.com"
           target="_blank">
            <span class="mr-2"><i class="far fa-fw fa-newspaper"></i></span>
            Tffl Blog
        </a>
        <a mat-menu-item
           routerLink="/info/rules">
            <span class="mr-2"><i class="fas fa-fw fa-book"></i></span>
            Rules
        </a>
        <!-- <a mat-menu-item
           routerLink="/info/faq">
            <span class="mr-2"><i class="fas fa-fw fa-question-circle"></i></span>
            FAQs
        </a>
        <a mat-menu-item
           routerLink="/info/contact">
            <span class="mr-2"><i class="fas fa-fw fa-phone"></i></span>
            Contact Us
        </a>
        <a mat-menu-item
           routerLink="/info/referral">
            <span class="mr-2"><i class="fas fa-fw fa-share"></i></span>
            Referral Codes
        </a> -->
    </ng-template>
</mat-menu>