import { Directive, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Directive({
    selector: '[appBackButton]'
})
export class BackButtonDirective {

    @Input() backRoute: string;

    constructor(
        private location: Location,
        private router: Router,
    ) {
    }

    @HostListener('click') onclick() {
        if (!this.backRoute) {
            this.location.back();
        } else {
            this.router.navigate([this.backRoute]);
        }
    }

}
