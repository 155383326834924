import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TfflClient, AuthService } from 'tffl-core';
import { first, switchMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HasAccessToUserGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private tfflClient: TfflClient,
        private router: Router
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const userId = next.paramMap.get('userId');

        return this.authService.getUserId().pipe(
            first(),
            switchMap(meId => this.tfflClient.users.isAuthorized(meId, userId)),
            map(isAuthorized => {
                return isAuthorized || this.router.createUrlTree(['/', 'players', userId]);
            })
        );
        // switchMap(
        //     ([playerId, loggedInUserId]) =>
        //         this.tfflClient.users.isAuthorized(loggedInUserId, playerId),
        //     (playerId, isAuthorized) => ({ playerId, isAuthorized })
        // ),
        // ).subscribe(({ playerId, isAuthorized }) => {
        //     this.isAuthorized = isAuthorized;
        //     this.initUser(playerId, isAuthorized);
        //     this.cdr.markForCheck();
        // });
        // // if (!this.authService.isLoggedIn() || !this.authService.isAdmin()) {
        // //     this.authService.setReturnUrl(state.url);
        // //     this.router.navigate(['/login']);
        // //     return false;
        // // }

        // return true;
    }


}
