import { Component, Input, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User, TfflClient } from 'tffl-core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
    user: User;
}

@Component({
    selector: 'app-argos-optin-modal',
    templateUrl: './argos-optin-modal.component.html',
    styleUrls: ['./argos-optin-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArgosOptinModalComponent implements OnInit {

    form: FormGroup;

    signupReasonOptions: { key: string, value: string }[];

    constructor(
        private fb: FormBuilder,
        private tfflClient: TfflClient,
        public dialogRef: MatDialogRef<ArgosOptinModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {

        this.signupReasonOptions = this.tfflClient.users.signupReasonOptions;

        this.form = this.fb.group({
            optin: [this.data.user.isArgosOptin || false, Validators.required],
            // favouriteArgosPlayer: [this.user.favouriteArgosPlayer],
            // argosGames: [this.user.argosGames],
            signupReason: [this.data.user.signupReason],
        });
    }

    dismiss() {
        let user = new User({
            id: this.data.user.id,
            hasBeenAskedArgosOptin: true,
        });

        this.tfflClient.users.update(user).subscribe(() => {
            this.dialogRef.close();
        });
    }

    submit() {

        let formValue = this.form.value;

        let user = new User({
            id: this.data.user.id,
            hasBeenAskedArgosOptin: true,
            isArgosOptin: formValue.optin,
            // favouriteArgosPlayer: formValue.favouriteArgosPlayer,
            // argosGames: formValue.argosGames,
            signupReason: formValue.signupReason,
        });

        this.tfflClient.users.update(user).subscribe(() => {
            this.dialogRef.close();
        });
    }

}
