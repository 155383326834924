import { Component, OnInit, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'tffl-core';

interface DialogData {
    user: User;
}

@Component({
    selector: 'app-create-account-notification',
    templateUrl: './create-account-notification.component.html',
    styleUrls: ['./create-account-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAccountNotificationComponent implements OnInit {
    
    constructor(
        public dialogRef: MatDialogRef<CreateAccountNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
    }
}
