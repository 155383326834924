import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-desktop-menu-bar',
    templateUrl: './desktop-menu-bar.component.html',
    styleUrls: ['./desktop-menu-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopMenuBarComponent implements OnInit {

    @Input() isAdmin: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

}
