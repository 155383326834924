<mat-expansion-panel expanded>
    <mat-expansion-panel-header expandedHeight="48px">
        <mat-panel-title>
            My Account
        </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list>
        <a mat-list-item
           routerLink="/dashboard"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-user-tie"></i></span>
            My Dashboard
        </a>
        <a mat-list-item
           routerLink="/staff"
           (click)="navigated.emit()"
           *ngIf="isStaff">
            <span class="mr-2"><i class="fas fa-fw fa-shopping-cart"></i></span>
            Staff Dashboard
        </a>
        <a mat-list-item
           routerLink="/cart/orders"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-shopping-cart"></i></span>
            My Cart
        </a>
        <a mat-list-item
           routerLink="/logout"
           (click)="navigated.emit()"
           *ngIf="!!user">
            <span class="mr-2"><i class="fas fa-fw fa-sign-out-alt"></i></span>
            Logout
        </a>
        <a mat-list-item
           routerLink="/login"
           (click)="navigated.emit()"
           *ngIf="!user">
            <span class="mr-2"><i class="fas fa-fw fa-sign-in-alt"></i></span>
            Login
        </a>
    </mat-nav-list>
</mat-expansion-panel>
<mat-divider></mat-divider>
<ng-container *ngIf="relatedUsers?.length">
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header expandedHeight="48px">
            <mat-panel-title>
                My Family
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
            <a mat-list-item
               [routerLink]="['/', 'players', u.id]"
               (click)="navigated.emit()"
               *ngFor="let u of relatedUsers">
                <div>
                    <span class="mr-2"><i class="fas fa-fw fa-user"></i></span>
                    <span class="strong">{{ u.name }}</span>'s player page
                </div>
            </a>
        </mat-nav-list>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
</ng-container>
<mat-expansion-panel expanded>
    <mat-expansion-panel-header expandedHeight="48px">
        <mat-panel-title>
            Register
        </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list>
        <a mat-list-item
           routerLink="/programs/youth"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-child"></i></span>
            Youth (Ages 9-16)
        </a>
        <a mat-list-item
           routerLink="/programs/girls"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-female"></i></span>
            Girls (Ages 9-16)
        </a>
        <a mat-list-item
           routerLink="/programs/little"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-graduation-cap"></i></span>
            Learn to Play (Ages 5-8)
        </a>
        <a mat-list-item
           routerLink="/programs/adult"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-user-tie"></i></span>
            Adult (Ages 17+)
        </a>
        <a mat-list-item
           routerLink="/programs"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-football-ball"></i></span>
            All Programs
        </a>
    </mat-nav-list>
</mat-expansion-panel>
<mat-divider></mat-divider>
<mat-expansion-panel expanded>
    <mat-expansion-panel-header expandedHeight="48px">
        <mat-panel-title>
            League
        </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list>
        <a mat-list-item
           routerLink="/attendance"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-football-ball"></i></span>
            My Games
        </a>
        <a mat-list-item
           routerLink="/schedule"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="far fa-fw fa-calendar"></i></span>
            Schedule
        </a>
        <a mat-list-item
           routerLink="/standings"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-chart-line"></i></span>
            Standings
        </a>
        <a mat-list-item
           routerLink="/statistics"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-trophy"></i></span>
            Statistics
        </a>
    </mat-nav-list>
</mat-expansion-panel>
<mat-divider></mat-divider>
<mat-expansion-panel class="mb-5">
    <mat-expansion-panel-header expandedHeight="48px">
        <mat-panel-title>
            News & Info
        </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list>
        <a mat-list-item
            href="//blog.torontoflagfootball.com"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="far fa-fw fa-newspaper"></i></span>
            News & Pics
        </a>
        <a mat-list-item
           routerLink="/info/get-involved"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-users"></i></span>
            Get Involved
        </a>
        <a mat-list-item
           routerLink="/info/rules"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-book"></i></span>
            Rules
        </a>
        <!-- <a mat-list-item
           routerLink="/info/faq"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-question-circle"></i></span>
            FAQs
        </a>
        <a mat-list-item
           routerLink="/info/contact"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-phone"></i></span>
            Contact Us
        </a>
        <a mat-list-item
           routerLink="/info/referral"
           (click)="navigated.emit()">
            <span class="mr-2"><i class="fas fa-fw fa-share"></i></span>
            Referral Codes
        </a> -->
    </mat-nav-list>
</mat-expansion-panel>