import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TfflComponentsModule } from 'tffl-components';
import { TfflMainComponent } from './tffl-main/tffl-main.component';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from './notification/notification.component';
import { ArgosOptinModalComponent } from './notification-dialogs/argos-optin-modal/argos-optin-modal.component';
import { CreateAccountNotificationComponent } from './notification-dialogs/create-account-notification/create-account-notification.component';
import { CreateRegistrationNotificationComponent } from './notification-dialogs/create-registration-notification/create-registration-notification.component';
import { JoinTeamNotificationComponent } from './notification-dialogs/join-team-notification/join-team-notification.component';
import { LoginNotificationComponent } from './notification-dialogs/login-notification/login-notification.component';
import { SentTeamMessageNotificationComponent } from './notification-dialogs/sent-team-message-notification/sent-team-message-notification.component';
import { FooterComponent } from './footer/footer.component';
import { DesktopMenuBarComponent } from './desktop-menu-bar/desktop-menu-bar.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { SharedModule } from '../shared/shared/shared.module';
import { MainNotFoundComponent } from './main-not-found/main-not-found.component';


@NgModule({
    declarations: [
        TfflMainComponent,
        NotificationComponent,
        ArgosOptinModalComponent,
        CreateAccountNotificationComponent,
        CreateRegistrationNotificationComponent,
        JoinTeamNotificationComponent,
        LoginNotificationComponent,
        SentTeamMessageNotificationComponent,
        FooterComponent,
        DesktopMenuBarComponent,
        MobileMenuComponent,
        MainNotFoundComponent,
        // HeaderComponent,
        // HeaderDesktopComponent,
        // HeaderMobileComponent,
    ],
    imports: [
        TfflComponentsModule,
        SharedModule,
        RouterModule,
    ],
    exports: [
        TfflMainComponent,
        NotificationComponent,
        MainNotFoundComponent,
    ]
})
export class MainModule { }
