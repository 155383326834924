<h1 mat-dialog-title>Welcome Back {{data.user.firstName}}!</h1>
<div mat-dialog-content>
    <form (ngSubmit)="submit()"
          [formGroup]="form">
        <mat-form-field appearance="fill">
            <mat-label>How did you hear about the league? (optional)</mat-label>
            <mat-select formControlName="signupReason">
                <mat-option *ngFor="let option of signupReasonOptions"
                            [value]="option.key">
                    {{ option.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="mb-1">
            Are you interested in the Argos Newsletter?
        </div>
        <mat-checkbox formControlName="optin"
                      class="text-wrap-checkbox">
            Yes, I would like to receive information, offers and promotions about the Toronto
            Argonautsfrom
            MLSE.
        </mat-checkbox>
        <div class="small mb-3">
            By clicking the above box, you are agreeing to receive electronic communications about the Toronto
            Argonauts
            from
            MLSE. You are free to unsubscribe at any time.
            <br>
            Maple Leaf Sports & Entertainment Partnership (MLSE), 50 Bay Street, Suite 500 Toronto, Ontario M5J 2L2,
            www.mlse.com
            <br>
            View the MLSE
            <a href="http://mlse.com/privacy-policy/"
               target="_blank">
                privacy policy
            </a>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button
            [mat-dialog-close]="false"
            cdkFocusInitial>Ask Me Later</button>
    <button mat-raised-button
            [mat-dialog-close]="true"
            color="primary-light"
            (click)="submit()"
            [disabled]="!form.valid">Save Response</button>
</div>