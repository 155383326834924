import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UrlTree } from '@angular/router';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit {

    @Input() urlTree: UrlTree;

    constructor() { }

    ngOnInit(): void {
    }

}
