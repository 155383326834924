import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Program } from 'tffl-core';

@Component({
    selector: 'app-selected-program-header',
    templateUrl: './selected-program-header.component.html',
    styleUrls: ['./selected-program-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedProgramHeaderComponent implements OnInit {

    @Input() program: Program;

    constructor() { }

    ngOnInit(): void {
    }

}
