import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TfflClient } from 'tffl-core';
import { LeaguePagesService } from './league-pages.service';

export const LeagueIdValue = new InjectionToken<string>('league-pages.config');

@Injectable({
  providedIn: 'root'
})
export class LeaguePagesGuard implements CanActivate {
    
    constructor(
        private tfflClient: TfflClient, 
        private leaugePagesService: LeaguePagesService,
        @Inject(LeagueIdValue) private leagueId: any,
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.tfflClient.seasons.getSeasons(this.leagueId).pipe(
            map(seasons => seasons.filter(s => s.hasStats)),
            tap(seasons => this.leaugePagesService.setSeasons(seasons)),
            map(() => true)
        );
    }
  
}
