import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'feetAndInches'
})
export class FeetAndInchesPipe implements PipeTransform {

    transform(val) {
        console.log('feet and inches', val);
        const feet = Math.floor(+val / 12);
        const inches = +val % 12;
        return feet + "'" + inches + '"';
    }

}
