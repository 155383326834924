<div class="footer">
    <div class="bg-black py-3 py-md-5">
        <div class="container">
            <div class="d-flex flex-wrap">
                <div class="mr-md-5 our-league-container">
                    <div class="h6 text-uppercase">Our League</div>
                    <p>
                        The Toronto Flag Football League is a non-contact flag football organization designed for
                        everyone who loves to play football. We strive to create a safe, fun, and captivating experience
                        for each of our players, by creating a professional league feel in a recreational league
                        setting, ensuring that everyone gets the utmost value out of their membership in the league.
                    </p>
                </div>
                <div class="contact-info mr-md-5">
                    <div class="d-inline-block">
                        <div class="h6 text-uppercase">Contact Info</div>
                        <p>
                            Toronto Flag Football League
                            <br> 905 – 1867 Yonge Street
                            <br> Toronto, ON
                            <br> M4S 1Y5
                        </p>
                        <p>
                            Phone: <a class="tffl-text-primary-light text-uncapitalize"
                               href="tel:416-366-8335">416-366-8335</a>
                            <br> Fax: 416-366-8335
                            <br> Email: <a class="tffl-text-primary-light text-uncapitalize"
                               href="mailto:info@torontoflagfootball.com">info@torontoflagfootball.com</a>
                        </p>
                    </div>
                </div>
                <div class="social-info">
                    <div class="h6 text-uppercase">Follow Us</div>
                    <div class="d-flex flex-wrap mb-3">
                        <a mat-icon-button
                           href="https://www.facebook.com/torontoflagfootball/"
                           target="_blank">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a mat-icon-button
                           href="https://www.instagram.com/torontoflagfootball/"
                           target="_blank">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                    <!-- <app-mailchimp-subscribe></app-mailchimp-subscribe> -->
                </div>
            </div>
        </div>
    </div>
    <div class="tffl-bg-primary tffl-text-contrast-primary py-3">
        <div class="container">
            <div class="d-flex justify-content-between">
                <div class="text-uppercase small-sm  mr-2">
                    <a class="border-right pr-1 mr-1 text-white"
                       routerLink="/info/about">About</a>
                    <a class="text-white"
                       routerLink="/info/rules">Rules</a>
                </div>
                <div class="small-sm">2018 © Toronto Flag Football League</div>
            </div>
        </div>
    </div>
</div>