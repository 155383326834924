import { Component, Input, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Registration } from 'tffl-core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
    registration: Registration
}

@Component({
    selector: 'app-join-team-notification',
    templateUrl: './join-team-notification.component.html',
    styleUrls: ['./join-team-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JoinTeamNotificationComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<JoinTeamNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
    }
}
