<h1 mat-dialog-title
    class="text-center">New Registration</h1>
<div mat-dialog-content>
    <div class="text-center mb-3">
        <div class="tffl-text-primary">
            <i class="fas fa-4x fa-check-circle"></i>
        </div>
        <div class="h3 mt-1">
            Success
        </div>
    </div>
    <div>
        <span class="strong">Next step:</span>
    </div>
    <div class="mb-3">
        <button mat-button
                color="primary-light"
                class="px-0"
                [mat-dialog-close]="false">Invite some friends to join you.</button>
    </div>
    <div *ngIf="showReferral()"
         class="mb-3">
        Refer a new friend and each of you can receive $10.
    </div>
    <div *ngIf="showStaff()"
         class="mb-3">
        We will look into your application and get back to you as soon as we can.
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button
            [mat-dialog-close]="false"
            cdkFocusInitial>Continue</button>
</div>