import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent implements OnInit {

    @Input() title: string = 'No title';
    @Input() subtitle: string = '';
    @Input() backbuttonText: string;
    @Input() backRoute: string;

    private _mobileQueryListener: () => void;

    constructor() {
    }

    ngOnInit() {}

    ngOnDestroy(): void {}

}
