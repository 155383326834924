<h1 mat-dialog-title
    class="text-center">Hi {{data.user.firstName}}!</h1>
<div mat-dialog-content>
    <div class="text-center mb-3">
        <div class="tffl-text-primary">
            <i class="fas fa-4x fa-check-circle"></i>
        </div>
        <div class="h3 mt-1">
            Welcome Back!
        </div>
    </div>
    <div class="mb-3 text-center">
        You can view your
        <a class="strong"
           routerLink="/dashboard"
           [mat-dialog-close]="false">dashboard</a> from the main menu.
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button
            [mat-dialog-close]="false"
            cdkFocusInitial>Continue</button>
</div>