import { InjectionToken } from '@angular/core';
import { environment } from './../environments/environment';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export interface AppConfig {
    production: boolean,
    googleMapsApiKey: string,
    apiBase: string,
    statsAppUrl: string,
    stripeKey: string,
    leagueId: string,
    angulartics2developerMode: boolean,
    isDriftOn: boolean,
}

export const TFFL_CONFIG: AppConfig = {
    production: environment.production,
    googleMapsApiKey: environment.googleMapsApiKey,
    apiBase: environment.apiBase,
    statsAppUrl: environment.statsAppUrl,
    stripeKey: environment.stripeKey,
    leagueId: environment.leagueId,
    angulartics2developerMode: environment.angulartics2developerMode,
    isDriftOn: environment.isDriftOn,
};

