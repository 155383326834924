import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'tffl-core';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
    
    constructor(
        public authService: AuthService,
        public router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (!this.authService.isLoggedIn() || !this.authService.isAdmin()) {
            this.authService.setReturnUrl(state.url);
            this.router.navigate(['/login']);
            return false;
        }

        return true;
    }
  
}
