import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { User } from 'tffl-core';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMenuComponent implements OnInit {

    @Input() isAdmin: boolean = false;
    @Input() isStaff: boolean = false;
    @Input() user: User = null;
    @Input() relatedUsers: User[] = [];
    
    @Output() navigated = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

}
