import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TfflEventService, TfflEvent, NotificationType, User, Registration, Team } from 'tffl-core';
import { MatDialog } from '@angular/material/dialog';
import { LoginNotificationComponent } from '../notification-dialogs/login-notification/login-notification.component';
import { ArgosOptinModalComponent } from '../notification-dialogs/argos-optin-modal/argos-optin-modal.component';
import { CreateAccountNotificationComponent } from '../notification-dialogs/create-account-notification/create-account-notification.component';
import { CreateRegistrationNotificationComponent } from '../notification-dialogs/create-registration-notification/create-registration-notification.component';
import { JoinTeamNotificationComponent } from '../notification-dialogs/join-team-notification/join-team-notification.component';
import { SentTeamMessageNotificationComponent } from '../notification-dialogs/sent-team-message-notification/sent-team-message-notification.component';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit {

    notifications: TfflEvent<any>[];

    constructor(
        private notificationService: TfflEventService,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.subscribe();
    }

    private subscribe() {
        this.notificationService.getNotifications().subscribe(notifications => {
            this.notifications = notifications;
            console.log('notifications', this.notifications);
            this.openNotification(notifications[notifications.length - 1]);
        });
    }

    onClose(notification: TfflEvent<any>) {
        notification.status = 'hidden';
    }

    openNotification(notification) {

        // let modalRef;

        let data = {};
        let component = null;

        switch (notification.type) {
            case NotificationType.LoggedIn:
                component = LoginNotificationComponent;
                data = { user: notification.item };
                break;
            case NotificationType.ArgosOptin:
                component = ArgosOptinModalComponent;
                data = { user: notification.item };
                break;
            case NotificationType.CreatedUser:
                component = CreateAccountNotificationComponent;
                data = { user: notification.item };
                break;
            case NotificationType.RegisteredPlayer:
                component = CreateRegistrationNotificationComponent;
                data = { registration: notification.item };
                break;
            case NotificationType.JoinedTeam:
                component = JoinTeamNotificationComponent;
                data = { registration: notification.item };
                break;
            case NotificationType.SentTeamMessage:
                component = SentTeamMessageNotificationComponent;
                data = { team: notification.item };
                break;
        }

        if (component) {
            this.dialog.open(component, { data, maxWidth: 500 });
        }
    }
}
